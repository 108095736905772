export default {
    path: '/statistic',
    component: () => import(/* webpackChunkName: 'layout' */'@/views/statistic/layout/index'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'statistic',
        component: () => import(/* webpackChunkName: 'user' */'@/views/statistic/index')
      },
      {
        path: '/statistic/project/siteVisit',
        name: 'siteVisit',
        component: () => import(/* webpackChunkName: 'user' */'@/views/statistic/project/siteVisit')
      },
      {
        path: '/statistic/project/siteVisitStatistics',
        name: 'siteVisitStatistics',
        component: () => import(/* webpackChunkName: 'user' */'@/views/statistic/project/siteVisitStatistics')
      },
      {
        path: '/statistic/projectTotalWorkTime',
        name: 'projectTotalWorkTime',
        component: () => import(/* webpackChunkName: 'user' */'@/views/statistic/project/projectTotalWorkTime')
      },
      {
        path: '/statistic/milestonePayInfo',
        name: 'milestonePayInfo',
        component: () => import(/* webpackChunkName: 'user' */'@/views/statistic/project/milestonePayInfo')
      },
    ]
  }