<template>
	<div class="table-page-container">
		<div class="page-header">
			<span class="title" v-if="title">{{ title }}</span>
			<div class="headerContent">
				<slot name="header"> </slot>
			</div>
		</div>
		<div class="page-body-table">
			<div class="table-container">
				<slot name="table"></slot>
			</div>
		</div>
		<div class="page-footer">
			<slot name="footer"></slot>
		</div>
	</div>
</template>
<script>
export default {
  props: ['title']
}
</script>
<style lang="scss" scoped>
.table-page-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	.page-header {
		display: flex;
		align-items: center;
		padding-bottom: 10px;
		overflow-x: auto;
		.title {
			padding-left: 5px;
			border-left: 2px solid #409eff;
			font-size: 20px;
			font-weight: 700;
			padding-left: 10px;
			min-width: 110px;
		}
	}
	.headerContent {
		flex: 1;
	}
	.page-body-table {
		flex: 1;
		position: relative;

		.table-container {
			position: absolute;
			width: 100%;
			height: 100%;
		}
	}
	.page-footer {
		z-index: 2;
	}
}
</style>