export default {
  path: '/cioms',
  component: () => import(/* webpackChunkName: 'cioms' */'@/views/cioms/layout/index'),
  meta: { requiresAuth: true },
  children: [
    {
      path: '/cioms/analysis',
      name: 'analysis',
      component: () => import(/* webpackChunkName: 'cioms' */'@/views/cioms/analysis')
    },
    {
      path: '/cioms',
      name: 'reportClass',
      component: () => import(/* webpackChunkName: 'cioms' */'@/views/cioms/reportClass')
    },
  ]
}