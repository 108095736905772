export default {
  path: '/userCard',
  component: () => import(/* webpackChunkName: 'userCard' */'@/views/userCard/layout/index'),
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      name: 'userCard',
      component: () => import(/* webpackChunkName: 'userCardIndex' */'@/views/userCard/index')
    },
  ]
}