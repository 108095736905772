export default {
  path: '/contracts',
  component: () => import(/* webpackChunkName: 'ctms' */'@/views/Contract/contracts/layout/index'),
  meta: { requiresAuth: true },
  children: [
    // {
    //   path: '/Contract/salesContract',
    //   name: 'salesContract',
    //   component: () => import(/* webpackChunkName: 'projectView' */'@/views/Contract/contracts/salesContract/index')
    // },
  ]
}