export default {
  path: '/help',
  component: () => import(/* webpackChunkName: 'helplayout' */'@/views/help/layout/index'),
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      name: 'help',
      component: () => import(/* webpackChunkName: 'help' */'@/views/help/index')
    },
  ]
}