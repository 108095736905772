export default {
  path: '/Contract',
  component: () => import(/* webpackChunkName: 'layout' */'@/views/Contract/layout/index'),
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      name: 'contract',
      component: () => import(/* webpackChunkName: 'user' */'@/views/Contract/index')
    },
    {
      path: '/Contract/salesContract',
      name: 'salesContract',
      component: () => import(/* webpackChunkName: 'projectView' */'@/views/Contract/contracts/salesContract/index')
    },
    {
      path: '/Contract/centralContract',
      name: 'centralContract',
      component: () => import(/* webpackChunkName: 'projectView' */'@/views/Contract/contracts/centralContract/index')
    },
    {
      path: '/Contract/tripartiteContract',
      name: 'tripartiteContract',
      component: () => import(/* webpackChunkName: 'projectView' */'@/views/Contract/contracts/tripartiteContract/index')
    },
    // {
    //   path: '/statistic/project/siteVisit',
    //   name: 'siteVisit',
    //   component: () => import(/* webpackChunkName: 'user' */'@/views/statistic/project/siteVisit')
    // },
    // {
    //   path: '/statistic/project/siteVisitStatistics',
    //   name: 'siteVisitStatistics',
    //   component: () => import(/* webpackChunkName: 'user' */'@/views/statistic/project/siteVisitStatistics')
    // }
  ]
}