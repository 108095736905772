/**
 * 存放一些静态变量、Map 等全局可用的变量
 */
/**
 * 未知文件类型图标
 */
export const unknownImg = require('_a/images/file/file_unknown.png')

/**
 * 文件展示模式选择列表时，所有可供选择的表格列名
 */
export const allColumnList = [
	'extend',
	'extendName',
	'fileSize',
	'file_size',
	'created_at',
	'createdAt',
	'deleteTime'
]

/**
 * 文件类型图标 Map 映射
 */
export const fileImgMap = new Map([
	['avi', require('_a/images/file/file_avi.png')],
	['bat', require('_a/images/file/file_powershell.png')],
	['c', require('_a/images/file/file_c.png')],
	['c++', require('_a/images/file/file_c++.png')],
	['c#', require('_a/images/file/file_c#.png')],
	['css', require('_a/images/file/file_css.png')],
	['go', require('_a/images/file/file_go.png')],
	['py', require('_a/images/file/file_python.png')],
	['styl', require('_a/images/file/file_stylus.png')],
	['less', require('_a/images/file/file_less.png')],
	['conf', require('_a/images/file/file_nginx.png')],
	['m', require('_a/images/file/file_objective_c.png')],
	['scss', require('_a/images/file/file_scss.png')],
	['sass', require('_a/images/file/file_sass.png')],
	['csv', require('_a/images/file/file_csv.png')],
	['dmg', require('_a/images/file/file_dmg.png')],
	['dirlock', require('_a/images/file/dirlock.png')],
	['dir', require('_a/images/file/dir.png')],
	['doc', require('_a/images/file/file_word.svg')],
	['docx', require('_a/images/file/file_word.svg')],
	['exe', require('_a/images/file/file_exe.png')],
	['html', require('_a/images/file/file_html.png')],
	['jar', require('_a/images/file/file_jar.png')],
	['java', require('_a/images/file/file_java.png')],
	['js', require('_a/images/file/file_js.png')],
	['json', require('_a/images/file/file_json.png')],
	['jsp', require('_a/images/file/file_jsp.png')],
	['kt', require('_a/images/file/file_kotlin.png')],
	['mp3', require('_a/images/file/file_music.png')],
	['flac', require('_a/images/file/file_flac.svg')],
	['oa', require('_a/images/file/file_oa.png')],
	['open', require('_a/images/file/file_open.png')],
	['pdf', require('_a/images/file/file_pdf.png')],
	['php', require('_a/images/file/file_php.png')],
	['ppt', require('_a/images/file/file_ppt.svg')],
	['pptx', require('_a/images/file/file_ppt.svg')],
	['properties', require('_a/images/file/file_properties.png')],
	['r', require('_a/images/file/file_r.png')],
	['rar', require('_a/images/file/file_rar.png')],
	['rs', require('_a/images/file/file_rust.png')],
	['rtf', require('_a/images/file/file_rtf.png')],
	['sh', require('_a/images/file/file_shell.png')],
	['sql', require('_a/images/file/file_sql.png')],
	['svg', require('_a/images/file/file_svg.png')],
	['swift', require('_a/images/file/file_swift.png')],
	['ts', require('_a/images/file/file_typescript.png')],
	['txt', require('_a/images/file/file_txt.png')],
	['vue', require('_a/images/file/file_vue.png')],
	['xls', require('_a/images/file/file_excel.svg')],
	['xlsx', require('_a/images/file/file_excel.svg')],
	['xml', require('_a/images/file/file_xml.png')],
	['zip', require('_a/images/file/file_zip.png')],
	['7z', require('_a/images/file/file_7z.svg')],
	['tar', require('_a/images/file/file_tar.svg')],
	['md', require('_a/images/file/file_markdown.png')],
	['markdown', require('_a/images/file/file_markdown.png')],
	['yaml', require('_a/images/file/file_yaml.png')],
	['yml', require('_a/images/file/file_yaml.png')],
	['jpg', require('_a/images/file/file_img.png')],
	['png', require('_a/images/file/file_img.png')],
	['jpeg', require('_a/images/file/file_img.png')],
	['tif', require('_a/images/file/file_img.png')],
	['tiff', require('_a/images/file/file_img.png')],
	['bmp', require('_a/images/file/file_img.png')],
	['gif', require('_a/images/file/file_img.png')],
	['mp4', require('_a/images/file/file_mp4.png')],
	['docm', require('_a/images/file/file_docm.png')]
])
